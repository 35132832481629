<template>
  <div>
    <v-row dense>
      <v-col :cols="12">
        <onboarding-wizard-heading
          :title="$t('pages.onBoarding.step03.selectYourBusinessIdeaType')"
          :description="$t('pages.onBoarding.step03.chooseTheOption')"
          top-space="0"
          :show-tick-mark="localData.category !== null"
        />

        <!--        <ib-button-basic-->
        <!--          icon="ib-icon-magic-2"-->
        <!--          color="violet"-->
        <!--          height="45px"-->
        <!--          class="onboarding-third-step-ai-button font-outfit-regular"-->
        <!--          :disabled="loading"-->
        <!--          @click="fetchSuggestions"-->
        <!--        >-->
        <!--          {{ $t('pages.onBoarding.step03.generateSuggestions') }}-->
        <!--        </ib-button-basic>-->

        <v-btn
          class="onboarding-third-step-ai-v-btn"
          outlined
          block elevation="0"
          height="45px"
          :loading="loading"
          @click="fetchSuggestions"
        >
          <i class="ib-icon ib-icon-magic-2" />
          <span class="font-outfit-regular">{{ $t('pages.onBoarding.step03.generateSuggestions') }}</span>
        </v-btn>

        <onboarding-suggestions
          ref="onboardingSuggestions"
          :suggestions="suggestions"
          :not-found-text="$t('pages.onBoarding.step03.noCategoryFound')"
          @click="setIdeaCategory($event)"
        />
      </v-col>

      <v-col :cols="12">
        <onboarding-wizard-heading :description="$t('pages.onBoarding.step03.orSelectTheOptionManually')" />

        <v-autocomplete
          v-model="localData.category"
          :label="$t('searchIdeaType')"
          item-value="id"
          :items="categories"
          :item-text="item => item.options ? item.options[lang].name : item.name"
          outlined
          dense
          height="46px"
          hide-details
          class="mb-2"
        >
          <!--          :prepend-icon="icons.mdiMagnify"-->
          <template #prepend-inner>
            <v-icon class="search-icon">
              {{ icons.mdiMagnify }}
            </v-icon>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
        v-if="template"
        :cols="12"
      >
        <onboarding-wizard-heading
          :title="$t('pages.onBoarding.step03.wouldYouLikeToUseTemplate')"
          :description="$t('pages.onBoarding.step03.startWithPreBuiltTemplate')"
          :show-tick-mark="localData.useTemplate !== null"
        />

        <v-chip-group
          v-model="localData.useTemplate"
          active-class="primary--text"
          column
          color="white"
        >
          <v-chip
            style="width: 133px; border: 1px solid #d9d9d9; height: 45px;"
            class="justify-content-center"
            filter
            label
            outlined
            :value="true"
            text-color="#292f4d"
          >
            {{ $t('yes') }}
          </v-chip>
          <v-chip
            style="width: 133px; border: 1px solid #d9d9d9; height: 45px;"
            class="justify-content-center"
            filter
            label
            outlined
            :value="false"
            text-color="#292f4d"
          >
            {{ $t('no') }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import OnboardingSuggestions from '@/views/Onboarding/Components/OnboardingSuggestions.vue'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'
import _find from 'lodash/find'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'OnboardingThirdStep',

  components: { OnboardingSuggestions, OnboardingWizardHeading },

  inject: ['hasCategoryTemplate', 'currentLang'],

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          category: null,
          useTemplate: null
        }
      }
    },

    categories: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      localData: {
        category: null,
        useTemplate: null
      },
      loading: false,
      suggestions: [],
      localSuggestions: [],
      icons: { mdiMagnify }
    }
  },

  computed: {
    template () {
      return this.hasCategoryTemplate()
    },

    lang () {
      return this.currentLang()
    }
  },

  watch: {
    'data.category' () {
      this.localData.useTemplate = null
    }
  },

  created () {
    this.localData = this.data
  },

  mounted () {
    EventBus.$on('onboarding-form-changed', (payload) => {
      this.localData = payload
    })
  },

  beforeDestroy () {
    EventBus.$off('onboarding-form-changed')
  },

  methods: {
    async fetchSuggestions () {
      this.suggestions = []
      try {
        this.loading = true
        const response = await this.$http.post('ai/idea-category-suggestions', { description: this.localData.description })

        const suggestions = response.data.payload.data

        const array = []

        if (suggestions.length) {
          suggestions.forEach(suggestion => {
            const itemExist = _find(this.categories, ['name', suggestion])

            if (itemExist) {
              array.push(itemExist)
            }
          })
        }

        if (array.length) {
          this.suggestions = array.map(item => item.options[this.lang].name)

          this.localSuggestions = array.map(item => {
            return {
              id: item.id,
              name: item.options[this.lang].name
            }
          })
        }

        if (!array.length) this.$refs.onboardingSuggestions.notFound = true

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    setIdeaCategory (name) {
      this.localData.category = _find(this.localSuggestions, ['name', name]).id
    }
  }
}
</script>

<style scoped lang="scss">
.onboarding-third-step-ai-button {
  width: 100%;
  text-align: center;
  justify-content: center;

  ::v-deep {
    .ib-icon {
      font-size: 22px;
      margin-right: 10px;
    }

    span {
      font-size: 17px;
    }
  }
}

.onboarding-third-step-ai-v-btn {
  text-transform: unset !important;
  letter-spacing: unset;
  font-size: 17px;
  color: $color-ai;
  border: 2px solid $color-ai;
  margin: 20px 0;

  i {
    font-size: 22px;
    margin-right: 10px;
  }
}

.search-icon {
  margin-top: 2px;
  //color: red;
}

</style>
