var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('onboarding-service-provider',{scopedSlots:_vm._u([{key:"default",fn:function({
      currentStep,
      nextStep,
      backStep,
      isFirstStep,
      isLastStep,
      form,
      validation,
      finish,
      loaders,
      categories,
      containerWidth,
      firstTime,
      stepperSteps
    }){return [_c('div',{staticClass:"onboarding-container"},[_c('img',{staticClass:"onboarding-page-logo",attrs:{"src":require("@/assets/img/logos/ideaBuddy-color.svg")}}),_c('div',{staticClass:"onboarding-container-box-wrapper"},[_c('div',{style:({'width': containerWidth})},[(loaders.general)?_c('loader'):[_c('onboarding-wizard-stepper',{staticClass:"mb-5",attrs:{"steps":stepperSteps,"hide-percentage-text":!firstTime,"current-step":!firstTime ? currentStep - 1 : currentStep}}),_c('onboarding-wizard-wrapper',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(currentStep === 0),expression:"currentStep === 0"}]},[_c('onboarding-user-details',{attrs:{"data":form}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(currentStep === 1),expression:"currentStep === 1"}]},[_c('onboarding-second-step',{attrs:{"data":form}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(currentStep === 2),expression:"currentStep === 2"}]},[_c('onboarding-third-step',{attrs:{"data":form,"categories":categories}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(currentStep === 3),expression:"currentStep === 3"}]},[_c('onboarding-fourth-step',{attrs:{"data":form}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(currentStep === 4),expression:"currentStep === 4"}]},[_c('onboarding-financial-settings',{attrs:{"data":form}})],1),_c('onboarding-wizard-footer',{attrs:{"validation":validation,"is-first-step":isFirstStep,"is-last-step":isLastStep,"loading":loaders.saving},on:{"next":nextStep,"back":backStep,"back-to-app":function($event){return _vm.$router.push({name: _vm.prevRouteName})},"finish":finish}})],1)]],2)])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }