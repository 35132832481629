<template>
  <div>
    <onboarding-wizard-heading
      class="mb-8"
      :title="$t('pages.onBoarding.step04.whatIsTheName')"
      :description="$t('pages.onBoarding.step04.ifYouHaveNotDecidedYet')"
      :show-tick-mark="localData.ideaName !== ''"
      text-center
      top-space="0"
    />

    <v-row dense class="mb-2">
      <v-col :cols="12" :md="5" class="mb-3 mb-md-0">
        <onboarding-wizard-idea-cover
          :cover="categoryCover"
          :icon="categoryIcon"
        />
      </v-col>

      <v-col :cols="12" :md="7">
        <v-text-field
          v-model="localData.ideaName"
          class="mb-2"
          :label="$t('pages.onBoarding.step04.ideaName')"
          outlined
          hide-details
          :disabled="loading"
          :loading="loading"
          dense
          height="46px"
          color="#8509F6"
        >
          <template #append-outer>
            <ib-tooltip popper-class="plan-popper">
              <div slot="content" class="tooltip-content">
                <p>{{ $t('generateIdeas') }}</p>
              </div>
              <ai-buddy-button-rounded class="button-vertical-align" @click="fetchSuggestions" />
            </ib-tooltip>
          </template>
        </v-text-field>

        <onboarding-suggestions
          :suggestions="suggestions"
          @click="localData.ideaName = $event"
        />

        <v-textarea
          v-model="localData.ideaSlogan"
          :label="$t('pages.onBoarding.step04.yourSlogan')"
          outlined
          hide-details
          rows="2"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
import EventBus from '@/event-bus'
import OnboardingSuggestions from '@/views/Onboarding/Components/OnboardingSuggestions.vue'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'
import OnboardingWizardIdeaCover from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardIdeaCover.vue'
import _find from 'lodash/find'

export default {
  name: 'OnboardingFourthStep',

  components: { AiBuddyButtonRounded, OnboardingSuggestions, OnboardingWizardHeading, OnboardingWizardIdeaCover },

  inject: ['selectedCategory', 'getCountries'],

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          ideaName: '',
          ideaSlogan: ''
        }
      }
    }
  },

  data () {
    return {
      localData: {
        ideaName: '',
        ideaSlogan: ''
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      suggestions: [],
      loading: false
    }
  },

  computed: {
    category () {
      return this.selectedCategory()
    },

    categoryCover () {
      if (!this.category) return ''

      return `${this.baseUrl}${this.category.cover.sizes.original}`
    },

    categoryIcon () {
      if (!this.category) return ''

      return `${this.baseUrl}${this.category.icon.sizes.original}`
    },

    countries () {
      return this.getCountries()
    },

    country () {
      const country = _find(this.countries, ['code', this.localData.country])
      if (!country) return ''

      return country.name
    }
  },

  created () {
    this.localData = this.data
  },

  mounted () {
    EventBus.$on('onboarding-form-changed', (payload) => {
      this.localData = payload
    })
  },

  beforeDestroy () {
    EventBus.$off('onboarding-form-changed')
  },

  methods: {
    async fetchSuggestions () {
      this.suggestions = []
      try {
        this.loading = true
        const response = await this.$http.post('ai/idea-name-suggestions', {
          description: this.localData.description,
          country: this.country,
          city: this.localData.city
        })

        this.suggestions = response.data.payload.data

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.button-vertical-align {
  margin-top: -8px;
}

</style>
