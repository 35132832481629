<template>
  <div
    class="idea-type-card d-flex flex-column text-center p-3"
    :class="classes"
    @click="$emit('click', type)"
  >
    <div class="image" :style="{backgroundImage: `url(${img})`}" />

    <p class="font-outfit-light card-title">
      {{ title }}
    </p>
    <p v-if="subtitle" class="card-subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'OnboardingIdeaStageCard',

  props: {
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    type: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        selected: this.active
      }
    }
  }
}
</script>

<style scoped lang="scss">
.idea-type-card {
  width: 100%;
  margin-right: 8px;
  border: 2px solid #D9D9D9;
  border-radius: 8px;
  cursor: pointer;
  opacity: .7;
  transition: all 0.25s;

  @include media-breakpoint-up($md) {
    width: 100px;
    height: 100px;
  }

  .card-title {
    font-size: 16px;
    margin: 12px 0 0;
  }

  .card-subtitle {
    color: $color-text-gray;
    font-size: 14px;
  }

  .image {
    width: 100%;
    min-height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    transition: all 0.25s;
    opacity: .7;
    filter: grayscale(100%);
  }

  &.selected {
    border: 2px solid $color-primary;
    opacity: 1;

    .image {
      filter: grayscale(0%);
    }
  }

  &:hover {
    opacity: 1;

    .image {
      filter: grayscale(0%);
    }
  }
}
</style>
