<template>

  <div class="onboarding-avatar-placeholder font-outfit-semi-bold">
    {{ text }}
  </div>

</template>

<script>
export default {
  name: 'OnboardingAvatarPlaceholder',

  props: {
    text: {
      type: String,
      required: true
    }
  }

}
</script>

<style scoped lang="scss">
.onboarding-avatar-placeholder {
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: asset("img/icons/profile-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5f6f6;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-size: 60%;
  text-transform: capitalize;
  font-size: 36px;
  color: $color-royal-blue;
  letter-spacing: 1.5px;
}
</style>
