<template>
  <div class="template-accordion-wrapper">
    <p class="cursor-pointer font-outfit-regular ma-0" @click="show = !show">
      {{ title }}
      <span :class="{'el-icon-arrow-down': !show, 'el-icon-arrow-up': show}" />
    </p>

    <div v-show="show">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IbBasicExpander',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.template-accordion-wrapper {
  p {
    font-size: 16px;
  }
  .el-icon-arrow-down,
  .el-icon-arrow-up {
    font-weight: 900;
    font-size: 14px;
  }
}
</style>
