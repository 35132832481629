<template>
  <div class="onboarding-wizard-stepper-container text-center">
    <div class="indicators">
      <template v-for="(step, index) in steps">
        <span
          :key="index"
          :class="{'active': currentStep >= index}"
        />
      </template>
    </div>
    <p v-if="!hidePercentageText" class="wizard-title font-outfit-bold">
      {{ completedPercentage }}% <span class="font-outfit-light ml-1">{{ $t('pages.onBoarding.onboardingCompleted') }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OnboardingStepper',

  inject: ['getCompletedPercentage'],

  props: {
    steps: {
      type: Array,
      required: true
    },

    currentStep: {
      type: Number,
      required: true
    },

    hidePercentageText: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    completedPercentage () {
      return this.getCompletedPercentage()
    }
  }
}
</script>

<style scoped lang="scss">
.onboarding-wizard-stepper-container {
  .indicators {
    display: flex;
    justify-content: center;
    column-gap: 5px;

    span {
      background-color: #d9d9d9;
      width: 14px;
      height: 14px;
      border-radius: 50%;

      &.active {
        background-color: #0E64E6;
      }
    }
  }

  .wizard-title {
    margin: 13px 0 25px 0;
    font-size: 18px;
  }
}
</style>
